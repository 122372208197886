
import * as serviceWorker from './serviceWorker';
import { createStore } from "redux";
import { Provider } from "react-redux";
import AllReducers from "./reducers";
import App from "./App";
import React from 'react'
import ReactDOM from "react-dom";


const store = createStore(

    AllReducers,

    // Development only: Allows debugging in React Store browser extension
    process.env.NODE_ENV === "development" ?
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        : undefined
);

// use Hydrate instead of render for server-side-rendering
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider >,
    document.getElementById("root"));
serviceWorker.unregister();
